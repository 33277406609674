import { AdminContext } from '@/contexts/admin-context';
import { ChangesContext } from '@/contexts/changes-context';
import { MerchantContext } from '@/contexts/merchant-context';
import getEnvVar from '@/utilities/environment-variable';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';

import SectionItem from '@/components/side-navigation/section-item';
import HeaderBarMenu from '@/components/header-bar/header-bar-menu';
import getNavigationSections from '@/utilities/getNavigationSections';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '@/public/icons/menu.svg';
import { useRouter } from 'next/router';
import { LocaleContext } from '@/contexts/locale-context';
import packageJson from '../../../package.json';

function SideNavigationV2() {
  const [logoTimestamp, setLogoTimestamp] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const cdnUrl = getEnvVar('REACT_APP_ASSETS_URL');
  const router = useRouter();
  const {
    getFeature, getConfigValue, name: merchantName, contactName: merchantContactName,
  } = useContext(MerchantContext);
  const {
    isAdmin, getCarrierLogo, getAdminFeature, isAdminReviewMode,
  } = useContext(AdminContext);
  const { publishingState, changeStatus } = useContext(ChangesContext);
  const { translate } = useContext(LocaleContext);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { sectionsStructure } = getFeature('navigation');
  const { privacyPolicyUrl, termsAndConditionsUrl } = getFeature('footer');
  const { privacyPolicyUrl: adminPrivacy, termsAndConditionsUrl: adminTerms } = getAdminFeature('footer');
  const { adminSectionStructure } = getAdminFeature('navigation');
  const assets = getConfigValue('assets');
  const processingEnabled = getConfigValue('processingEnabled');
  const isIntegrated = getConfigValue('isIntegrated');
  const portalActive = getConfigValue('portalActive');
  const merchantNameType = merchantContactName || merchantName;

  const merchantNameV2 = isAdmin ? 'A' : merchantNameType;

  const carrierLogo = () => getCarrierLogo();

  const sectionStructureToUse = useMemo(() => {
    if (isAdmin && !isAdminReviewMode) {
      return adminSectionStructure;
    }

    if (!sectionsStructure) {
      return {};
    }

    const filteredOptions = getNavigationSections({
      structure: sectionsStructure,
      isAdminReviewMode,
      processingEnabled,
      isIntegrated,
      portalActive,
    });
    return filteredOptions;
  }, [isAdmin, adminSectionStructure, sectionsStructure, isAdminReviewMode]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (publishingState === changeStatus.NO_CHANGES) {
      setLogoTimestamp(`?ts=${Date.now()}`);
    }
  }, [publishingState]);

  useEffect(() => {
    // close menu on route change
    const handleRouteChange = () => {
      setIsMenuOpen(false);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
  return (
    <div id="side-navigation">
      <div className="header">
        {isMobile && (
          <button className={`menu-button ${isMenuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
            <MenuIcon className="arrow-down" />
          </button>
        )}
        <div>
          <img className="company-logo" alt="" src={`${cdnUrl}/${assets?.logoImage}${logoTimestamp}`} />
        </div>
        <div className="user-wrapper">
          <HeaderBarMenu merchantName={merchantNameV2} />
        </div>
      </div>
      <div className={`sections-wrapper ${isMenuOpen ? 'open' : ''}`}>
        <div className="top-section">
          {sectionStructureToUse.topSection?.map((section) => (
            <SectionItem key={section.title} section={section} />
          ))}
        </div>
        <div className="bottom-section">
          <div className="sections">
            {sectionStructureToUse.bottomSection?.map((section, index) => (
              <SectionItem key={section.title.length ? section.title : `${section.title}-${index}`} section={section} />
            ))}
          </div>
          <div className="footer-section">
            <div className="carrier-logo-wrapper">
              <img alt="carrier logo" src={carrierLogo()} />
            </div>
            <div className="additional-info-section">
              <div className="version-wrapper">
                {`${translate('constants.version')} ${packageJson.version}`}
              </div>
              <div className="footer-link-section">
                {(privacyPolicyUrl || adminPrivacy)
                && (
                  <>
                    <a id="footer-link-privacy" className="footer-link" href={privacyPolicyUrl || adminPrivacy}>{translate('constants.footerPrivacyLabel')}</a>
                  </>
                )}
                {(termsAndConditionsUrl || adminTerms)
                && (
                  <>
                    <div className="spacer" />
                    <a id="footer-link-terms" className="footer-link" href={termsAndConditionsUrl || adminTerms}>{translate('constants.footerT&C')}</a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`

          .arrow-down {
            position: absolute;
            display: inline-block;
            right: 5px;
            transition: transform .24s;
          }

          .arrow-down.open {
            transform: rotate(180deg);
          }
          
          #side-navigation {
            padding: 15px 16px 28px 16px;
            position: fixed;
            border-right: 1px solid #e9eaea;
            height: 100vh;
            left: 0;
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 280px;
            max-width: 280px;
            overflow-y: auto;
          }

          .sections-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
          }

          .top-section {
            display: flex;
            flex-direction: column;
            gap: 40px;
          }

          .bottom-section {
            display: flex;
            flex-direction: column;
            gap: 28px;
          }

          .bottom-section .sections {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-bottom: 28px;
            border-bottom: 1px solid #E4E4E4;
          }

          .header {
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .company-logo {
            max-height: 26px;
          }

          .carrier-logo-wrapper img {
            max-width: 100%;
            max-height: 16px;
          }

          .footer-section {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
          
          .additional-info-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .version-wrapper {
            color: var(--color-dark-grey);
            font-size: 11px;
          }

          .footer-link-section {
            display: flex;
            flex-direction: row;
          }

          .footer-link {
            color: var(--color-dark-grey);
            cursor: pointer;
            display: flex;
            font-size: 11px;
            position: relative;
            padding: 3px 5px;
          }

          .spacer {
            display: flex;
            justify-content: center;
            width: 12px;
          }

          .spacer::before {
            background-color: var(--color-line-grey);
            content: "";
            height: 18px;
            width: 1px;
          }

          @media (max-width: 768px) {
            #side-navigation {
              width: ${isMenuOpen ? '100vw' : '0'};
              max-width: 100vw;
              z-index: var(--zindex-fixed);
              padding: 0;
              gap: 0;
            }

            .header {
              height: 56px;
              padding: 16px;
              justify-content: space-between;
              border-bottom: 1px solid #E5E7EB;
              background: #fff;
              position: fixed;
              width: 100vw;
            }

            .menu-button {
              background: none;
              border: none;
              cursor: pointer;
              padding: 0;
              margin: 0;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
            }

            .menu-button.open {
              background: #f5f5f5;
            }

            .sections-wrapper {
              position: fixed;
              top: 56px;
              transition: all .24s;
              transform: translateX(-100%);
              z-index: 1000;
              background: #fff;
              padding: 16px;
              max-width: 280px;
              width: 280px;
              height: calc(100vh - 56px);
              border-right: 1px solid #E4E4E4;
              overflow-y: auto;
            }

            .sections-wrapper.open {
              transform: translateX(0);
            }
          }

        `}
      </style>
    </div>
  );
}

export default SideNavigationV2;
