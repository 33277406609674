import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { ThemeContext } from '@/contexts/theme-context';
import { MerchantContext } from '@/contexts/merchant-context';
import { AdminContext } from '@/contexts/admin-context';
import { RoutingContext } from '@/contexts/routing-context';
import HeaderBar from '@/components/header-bar';
import TextDiv from '@/components/text-div';
import SideNavigation from '@/components/side-navigation';
import Footer from '@/components/footer';
import AdminInfoPanel from '@/components/admin-info-panel';
import { LocaleContext } from '@/contexts/locale-context';
import JaFontsOverride from '@/components/full-navigation/ja-fonts-override';
import SentryLogger from '@/utilities/telemetry/sentry';
import SideNavigationV2 from '@/components/side-navigation/side-navigation-v2';
import packageJson from '../../../package.json';

export default function FullNavigation({ children }) {
  const {
    error: merchantError,
  } = useContext(MerchantContext);
  const {
    isAdminReviewMode, isAdmin, error: adminError, isNavBarV2,
  } = useContext(AdminContext);
  const { backgroundColor } = useContext(ThemeContext);
  const { showNavigation, showHeaderOnlyForRoute, showVersion } = useContext(RoutingContext);
  const { currentLocale, translate } = useContext(LocaleContext);
  const router = useRouter();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (merchantError || adminError) {
      SentryLogger.logEvent({ eventName: 'page_load_failure', message: `Page load failed at path: ${router.pathname}` });
    }
  }, [merchantError, adminError]);

  if (merchantError || adminError) {
    return (
      <div className="error">
        <TextDiv>{translate('constants.apiErrors.default')}</TextDiv>
        <style jsx>
          {`
          .error {
            position: fixed;
            left: 50%;
            line-height: 1.5;
            top: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            white-space: break-spaces;
          }
        `}
        </style>
      </div>
    );
  }

  if (!showNavigation()) {
    return (
      <>
        {showHeaderOnlyForRoute() && (
        <HeaderBar logoOnly />
        )}
        {children}
        <Footer />
      </>
    );
  }

  // to be fully amended in RET-1588 and RET-1589
  if (isNavBarV2()) {
    return (
      <>
        <div className="full-navigation">
          <SideNavigationV2 />
        </div>
        <div className="content-area">
          {children}
          <div />
        </div>
        <style jsx>
          {`
          .full-navigation {
            position: relative;
          }

          .content-area {
            position: absolute;
            width: calc(100% - 280px);
            min-height: 100vh;
            left: 280px;
            background-color: ${backgroundColor};
            flex-grow: 1;
          }

          @media (max-width: 768px) {
            .content-area {
              width: 100%;
              left: 0;
              margin-top: 56px;
            }
          }
        `}
        </style>
      </>
    );
  }

  return (
    <>
      <div className="full-navigation">
        {currentLocale === 'ja' && <JaFontsOverride />}
        <HeaderBar />
        {isAdmin && <AdminInfoPanel />}
        {!isMobile && (
        <SideNavigation selectedRoute={router.asPath} />
        )}
        <div className={`content-area ${isAdminReviewMode ? 'review-mode' : ''}`}>
          {children}
          {isMobile && showVersion() && (
            <div className="app-version">
              <div className="version-wrapper">
                {`${translate('constants.version')} ${packageJson.version}`}
              </div>
            </div>
          )}
        </div>
        <style jsx>
          {`
          .full-navigation {
            position: relative;
          }

          .content-area {
            position: absolute;
            width: calc(100% - 280px);
            min-height: calc(100vh - 71px);
            left: 280px;
            background-color: ${backgroundColor};
            flex-grow: 1;
            padding: 30px 40px;
            margin-top: 70px;
          }

          .content-area.review-mode {
            min-height: calc(100vh - 121px);
            margin-top: 120px;
          }

          .app-version {
            color: var(--color-primary-dark);
            font-size: var(--font-size-H4);
            text-align: center;
            width: 100%;
          }

          @media (max-width: 768px) {
            .content-area {
              width: 100%;
              left: 0;
              padding: 10px;
              margin-top: 130px;
            }

            .content-area.review-mode {
              min-height: calc(100vh - 221px);
              margin-top: 220px;
            }
          }
        `}
        </style>
      </div>
      <Footer />
    </>
  );
}

FullNavigation.defaultProps = {
  children: null,
};

FullNavigation.propTypes = {
  children: PropTypes.node,
};
